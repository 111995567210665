import React from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import WorkInProgressBanner from "../components/work-in-progress-banner"

export default ({ data, pageContext }) => {
  const { nextPage } = pageContext
  const page = data.markdownRemark
  return (
    <>
      <Header />
      <Layout>
        <div className="prose lg:prose-lg max-w-full">
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
          {nextPage && (
            <div className="pt-6">
              <div className="text-right">
                <Link to={nextPage.fields.slug}>
                  <div className="font-bold flex justify-end items-center">
                    {nextPage.frontmatter.title}
                    <svg
                      className="h-5 w-5 pl-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
        <WorkInProgressBanner />
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
